var render = function () {
  var _vm$dropdownActiveCon, _vm$dropdownActiveCon2, _vm$user, _vm$user2, _vm$user3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "gn-app-header"
  }, [_c('div', {
    staticClass: "gn-app-header__container"
  }, [_c('router-link', {
    staticClass: "gn-app-header__logo",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png")
    }
  })]), _c('div', {
    staticClass: "gn-app-header__fields"
  }, [_c('div', {
    staticClass: "gn-app-header__companies"
  }, [_c('GenproxLeSelect', {
    attrs: {
      "options": _vm.dropdownUserContextOptions,
      "hasAfterlist": _vm.hasPrivateFundManager,
      "image": ((_vm$dropdownActiveCon = _vm.dropdownActiveContext) === null || _vm$dropdownActiveCon === void 0 ? void 0 : _vm$dropdownActiveCon.image) || null,
      "label": (_vm$dropdownActiveCon2 = _vm.dropdownActiveContext) === null || _vm$dropdownActiveCon2 === void 0 ? void 0 : _vm$dropdownActiveCon2.text
    },
    on: {
      "input": _vm.contextHandler,
      "addNewFund": function addNewFund($event) {
        return _vm.$router.push({
          name: 'select-fund'
        });
      }
    },
    model: {
      value: _vm.activeUserContextId,
      callback: function callback($$v) {
        _vm.activeUserContextId = $$v;
      },
      expression: "activeUserContextId"
    }
  })], 1), _c('div', {
    staticClass: "gn-app-header__profile"
  }, [_c('GenproxProfileMenu', {
    attrs: {
      "image": ((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.logo) || null,
      "label": ((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.firstName) || ((_vm$user3 = _vm.user) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.email)
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }